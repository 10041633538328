import { A11y, Navigation, Pagination, Scrollbar, Keyboard } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Image from "next/image";
import React, { useEffect, useState } from "react";
import { Tactic } from "@/types/tactic";
import { useRouter } from "next/router";

export const Manga = ({ tactic }: { tactic: Tactic }) => {
  const router = useRouter();
  const [swiper, setSwiper] = useState(null);
  useEffect(() => {
    if (swiper) {
      // @ts-ignore
      swiper.slideTo(0);
    }
  }, [tactic, router.pathname, swiper]);

  return (
    <div className={"px-4 md:px-12 pt-5 pb-10 overflow-hidden bg-gray-900"}>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y, Keyboard]}
        spaceBetween={5}
        slidesPerView={1}
        navigation
        scrollbar={{ draggable: true }}
        pagination={{ clickable: true }}
        onSwiper={() => setSwiper}
        keyboard={{
          enabled: true,
          onlyInViewport: false,
        }}
      >
        {tactic?.tactic_sliders.map((slider) => (
          <SwiperSlide key={slider.id}>
            <div className={"md:h-150 text-center"}>
              <Image
                src={slider.image}
                alt=""
                width={400}
                height={400}
                objectFit="contain"
                loading={"eager"}
                className={"mx-auto"}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
