import React from "react";
import { Head } from "@/components/base/Head";
import { News } from "@/types/news";
import { default as NextHead } from "next/dist/shared/lib/head";
import Link from "next/link";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import Image from "next/image";
import { microCMSClient } from "@/utils/client";
import { format } from "date-fns";
import ja from "date-fns/locale/ja";
import { SupportBanners } from "@/components/base/ui/SupportBanners";
import { ScrollToTop } from "@/components/base/ui/ScrollToTop";
import { fetchTactic } from "@/repositories/tactic";
import useSWR from "swr";
import { Manga } from "@/components/index/Manga";

const TopPage = ({ news }: { news: News[] }) => {
  const { data } = useSWR(
    `/api/v1/tactics/93e07a73-47f4-426b-aef2-6605525d7b57`,
    (url) => fetchTactic(url)
  );
  const banners = [
    {
      label: "経営者向け",
      title: "経営見える化診断",
      image: "banner-visibility.png",
      description:
        "「経営見える化診断」は、経営理念やビジョン、労働環境、業績、ITツールの活用など、多角的観点から経営状況を診断します。弱点発見と改善ヒント提供で、経営の透明性向上に役立てましょう。",
      slug: "/diagnostics/visibility/questions",
    },
    {
      label: "経営者向け",
      title: "DX診断",
      image: "banner-dx.png",
      description:
        "社内のデジタル化は進んでいますか？基礎力、働き方、効率化、魅力を伝える、会計という5つの観点から、DX状況を丸見えに。弱点の把握に加え、改善のヒントが得られます。",
      slug: "/diagnostics/dx/questions",
    },
    {
      label: "経営者向け",
      title: "SDGs診断",
      image: "banner-sdgs.png",
      description:
        "近年、SDGsの考え方は広く浸透し、建設業を代表例に、SDGsに取り組むことで有利になるシーンも増えてきています。具体的な診断項目を活用して、会社のSDGsへの取り組みを総括しましょう。",
      slug: "/diagnostics/sdgs/questions",
    },
    {
      label: "経営者・社員向け",
      title: "仕事タイプ診断",
      image: "banner-job.png",
      description:
        "自分が仕事人としてどんな強みや素養を持っているのかを知ることができます。さらに社内でシェアすることにより、最適なチーム編成も可能になります。無料でご利用頂けます。",
      slug: "/jobtypes",
    },
    {
      label: "経営者・社員向け",
      title: "「忙しい!」レベル診断",
      image: "banner-busy.png",
      description:
        "口癖のように「忙しい！」と言ってしまっていませんか？そんなあなたの忙しさを数値化して診断します。「忙しい！」を解消し、うまく仕事を回すヒントが得られます。",
      slug: "/busy",
    },
    {
      label: "経営者・社員向け",
      title: "思考力診断",
      image: "comming-soon2.png",
      description:
        "普段から自分の知識や経験をもとに考え、結論を導き出しているかを知ることができます。診断結果をもとに思考力向上に努めていきましょう。",
      slug: "",
    },
  ];

  const Banner = ({
    slug,
    label,
    image,
    title,
    description,
  }: {
    slug: string;
    label: string;
    image: string;
    title: string;
    description: string;
  }) => {
    return (
      <div className={"md:mb-0 mb-5"}>
        <h3 className={"text-[#0D1D47] font-bold mb-2 flex"}>
          <span className={"bg-blue-700 flex w-1 mr-2"} />
          {label}
        </h3>
        <Link
          href={slug}
          className={"hover:opacity-60 duration-300 max-w-[480px] block m-auto"}
        >
          <span className={"leading-[0] block"}>
            <Image
              src={`/images/index/${image}`}
              width={480}
              height={236}
              alt={title}
            />
          </span>
          <p className={"text-[#0D1D47] bg-white text-sm py-3"}>
            {description}
          </p>
        </Link>
      </div>
    );
  };

  const voices = [
    {
      image: "olympus-ceo.png",
      companyName: "株式会社 OLYMPUS.",
      name: "代表取締役 長谷川 忠久様",
      description:
        "中小企業の戦略ラボの講座を使い、弊社の総務部長が主になり社内勉強会を行いました。動画を見てみんなで意見を出し合い重要性を再確認。「報・連・相」なめてました！月一ミーティングの後に勉強会を入れて今後も進めていこうと思っています。",
    },
    {
      image: "tagawa-sama.png",
      companyName: "E. Sewing Garden",
      name: "田川恵理子様",
      description:
        "経営者は多岐にわたり学びを要しますが、「場所」「時間」「日程」を問わず、時短傾向であるご時世にコンパクトに学べる環境は必須であると考えます。「戦略ラボ」は隙間時間でサクッと、経理からビジネスマナーまで、経営者から新入社員までともに学べるツールです。",
    },
    {
      image: "nakazawa-sama.png",
      companyName: "株式会社キティック",
      name: "中澤洋一様",
      description:
        "中小企業も電子保存の義務化で業務の見直しが必要になりますが、対応は弊社もまだ不十分と言えます。しかし戦略ラボなら「どう対応すればよいのか」が分かりやすく学べます。中小企業の課題の多くは「担当者不足、知識不足」によるものが大きいと思いますが、このあたりの課題を短時間で解決できるのはとてもありがたいです。",
    },
    {
      image: "yamamoto-sama.png",
      companyName: "株式会社MTC",
      name: "山本光治郎様",
      description:
        "周りに相談できない経営知識から今後役に立つ経営ノウハウまで、漫画と動画で分かりやすく教えてくれます。１つの講座が5～10分程度だから、自分のタイミングやペースで進められるのも大きな魅力。力も途切れず集中して学ぶことができます。そして漫画だから楽しく読める！自分が楽しみながら学べるから社員にも勧めやすいです。",
    },
  ];

  const free_contents = [
    {
      image: "top_mierukasheet.png",
      headline: "戦略見える化シート",
      subheading:
        "質問に答えるだけで事業計画が作れる、経営管理が簡単にできる！",
      detail:
        "中小企業の社長が最も苦手とするもの、それが「事業計画（経営計画）」です。事業計画は自社の方向性や実施事項を文章で分かりやすく示す必要があるため、まず何から書けば良いのか分からないというお悩みが…。そんなお悩みを解決するために、質問に答えるだけで事業計画が出来上がるサービスを作りました。",
    },
    {
      image: "top_manga1.png",
      headline: "オリジナル漫画（１日１コンテンツ）",
      subheading:
        "１日１０分のスキマ時間で、継続的に楽しくビジネス学習ができる！",
      detail:
        "人材育成はどの中小企業においても課題です。巷にあふれる教材は難しく、継続的に学んでくれないという声も。そこで戦略ラボでは、誰でも興味を持ってスキマ時間で学べるよう、わずか10分間で分かりやすく学べるオリジナル漫画のコンテンツを多数揃えました。",
    },
    {
      image: "top_seminar.png",
      headline: "セミナー・勉強会の参加",
      subheading: "経営課題の解決に必要な知識やノウハウを学ぶことができる！",
      detail:
        "組織づくりや業務効率化、マーケティングなどの経営に必要な知識やノウハウを短時間で習得できます。また少人数の勉強会も定期的に開催しており、様々な意見を取り入れながら自社の経営力を強化する場もご用意しております。",
    },
    {
      image: "top_template.png",
      headline: "カスタムフォームで学習の実践を後押し",
      subheading:
        "社内研修も日報も顧客アンケートも、これ一つで全て作成できる！",
      detail:
        "用途によって自由にレイアウトや文言の変更が可能なテンプレートが使える！社内研修のワークシートも日々の業務日報も顧客アンケートも、これ一つで全て作成することが可能です。すぐに使えるテンプレートも複数ご用意しています。",
    },
    {
      image: "top_task.png",
      headline: "タスク管理でタスクを一元管理",
      subheading: "目標達成に向け、日々やるべきことを一元管理できる！",
      detail:
        "いくら学習しても、実行に移さなければ意味がない…。そこで目標の達成に向けたアクションプランを設定し、日々やるべきことは何かを一元的に管理できる機能をご用意しました。会社目標や部門別目標からもタスクを個別に設定できます。",
    },
    {
      image: "top_logictree.png",
      headline: "学習・分析ツールで思考力を養う",
      subheading:
        "ロジックツリーをはじめ、経営や学習に欠かせないツールが満載！",
      detail:
        "思考力を養い、論理的に物事を考えるために実用なロジックツリーをはじめ、経営や学習に役立つツールが満載！アイデア出しや業務改善のほか、市場動向の分析や自社の戦略方針を検討する際にも活用できます。",
    },
    {
      image: "top_management-info.png",
      headline: "経営の最新コラム",
      subheading:
        "補助金情報や法改正、課題別テーマなどの最新情報を閲覧できる！",
      detail:
        "今知っておかなければならない経営の情報を、コラム形式でサッと読むことができます。特に補助金・助成金の情報や、税制改正・労働法改正など、経営にとって必要な最新情報を定期的に配信しているため、自社にとって何が必要かをリアルタイムで知ることができます。",
    },
    {
      image: "top_diagnose.png",
      headline: "オリジナル診断ツール",
      subheading: "タイプ別診断やDX診断、SDGs診断などが使い放題！",
      detail:
        "SDGs診断やDX診断、経営診断などの各種診断ツールが使い放題！自社の弱みや不足しているものが客観的に分析でき、次の一手に繋げることができます。また従業員の特性が分かるタイプ別診断も使い放題なので、人材別にどのようなマネジメントを行えば良いかのヒントにもなります。",
    },
  ];

  const premium_contents = [
    {
      image: "top_premium_manga.png",
      headline: "オリジナル漫画が見放題",
      subheading: "経営やビジネスの学習に役立つオリジナル漫画と講座が見放題！",
      detail:
        "無料会員登録では1日1コンテンツしか見ることができませんが、プレミアムプランでは全ての漫画と講座が見放題！ストレスなく学習が出来るほか、社内や戦略ラボ全体のランキングもあり、日々の学習を継続することができます。",
    },
    {
      image: "top_employee.png",
      headline: "ユーザーの無制限招待",
      subheading: "従業員が10人でも100人でも、無制限に戦略ラボへの招待が可能！",
      detail:
        "戦略ラボは従業員数に関係なく無制限に招待が可能！従来のサービスでは招待する人数に制限があるのが一般的でしたが、戦略ラボでは「全員経営」を推奨しているため、従業員数によって上限が無く無制限に招待することが可能です。",
    },
    {
      image: "top_studysituation.png",
      headline: "ダッシュボードで学習・業務状況が分かる",
      subheading:
        "学習の進捗状況が一目で分かり、人材育成やマネジメントに活用できる！",
      detail:
        "招待した全ての人の実施状況が一目で分かる！誰がどのくらい学習や業務を実施しているかを客観的に把握することで、各自の継続的な習慣づけを促せるほか、評価制度や表彰などと紐付けることも可能であるため、従業員の意欲向上や学びの定着に役立ちます。",
    },
    {
      image: "top_chat.png",
      headline: "いつでもチャットで社内コミュニケーションを円滑に",
      subheading: "学習内容の共有や実践に向けた進捗管理が可能",
      detail:
        "チャットツールは使い方が難しいからこそ、戦略ラボでは必要な機能を絞ってよりシンプルにしました。社内の報連相ややるべきことのリマインドに加え、活発なコミュニケーションも促すことができます。",
    },
    {
      image: "top_mierukasheet.png",
      headline:
        "【オプションプラン】プロのサポートで社内学習の定着を協力に推進",
      subheading:
        "事業計画の策定から実践、そして社内学習の定着までプロの専門家がオンラインでサポート",
      detail:
        "経営学習だけでなく、社内学習もプロの専門家が定着までしっかりサポートします。年間を通じてプロがあなたの伴走支援をすることで、経営の着実な改善と自社利益率の大幅な向上につなげることができます。",
    },
  ];

  return (
    <>
      <Head canonicalPath={"/"} />
      <NextHead>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700;900&display=swap"
          rel="stylesheet"
        />
      </NextHead>
      <main className={"pb-20"}>
        <div className={"text-center leading-0 border"}>
          <div className={"hidden md:block"}>
            <Image
              src={"/images/index/kv.png"}
              width={1280}
              height={441}
              alt={""}
              className={"m-auto"}
            />
          </div>
          <div className={"md:hidden border"}>
            <Image
              src={"/images/index/kv.sp.png"}
              width={400}
              height={350}
              alt={""}
            />
          </div>
        </div>
        <section className={"md:px-0 px-2 mt-10 mb-20"}>
          <div
            className={
              "md:p-10 p-5 bg-white overflow-hidden max-w-[1160px] md:mx-auto my-10 border"
            }
          >
            <div className={"md:flex flex-1 overflow-hidden mb-8"}>
              <h2
                className={
                  "flex items-center text-lg font-bold md:border-r border-gray-900 md:pr-10 md:mr-10 pr-3 mr-3"
                }
              >
                <span
                  className={
                    "leading-[1.75] text-sm md:text-base mb-3 md:mb-0 text-[#e9530c]"
                  }
                >
                  お知らせ
                </span>
              </h2>
              <div className={"space-y-2 flex-1"}>
                {news.map((_news, index) => (
                  <div key={index}>
                    <Link
                      href={`/news/${_news.id}`}
                      className={"flex md:items-center hover:text-blue-700"}
                    >
                      <span
                        className={
                          "md:mr-5 mr-1 text-gray-500 text-sm w-[100px] text-[#1D4ED8]"
                        }
                      >
                        {format(new Date(_news.publishedAt), "Y/MM/dd", {
                          locale: ja,
                        })}
                      </span>
                      <p className={"md:text-base text-sm flex-1"}>
                        {_news.title}
                      </p>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            <Link
              href="/news"
              className={
                "flex items-center justify-center font-bold pl-2 text-sm md:text-base max-w-[300px] min-h-[56px] m-auto bg-[#1D4ED8] shadow rounded hover:opacity-60 text-white"
              }
            >
              お知らせ一覧へ
              <ArrowRightIcon className={"w-5 ml-4"} />
            </Link>
          </div>
        </section>

        <section className={"max-w-[1160px] mx-auto mt-8 px-4"}>
          <div className={"text-[#0D1D47] mb-8"}>
            <h2
              className={
                "tracking-[-2px] md:tracking-normal text-2xl md:text-3xl mb-10 font-bold pt-10 text-center"
              }
            >
              あなたの会社は何点？
              <br className={"md:hidden"} />
              あなたと社員はどのタイプ？
            </h2>
            <p className={""}>
              戦略ラボでは、企業規模が100人以下の経営者のお悩みに特化した無料の診断ツールを用意しております。
              <br />
              会員登録不要でご利用頂けるので、ぜひご活用ください。
            </p>
          </div>
          <div className={"md:grid grid-cols-3 gap-3 m-auto"}>
            {banners.map((banner, index) => (
              <div key={index}>
                <Banner
                  slug={banner.slug}
                  title={banner.title}
                  description={banner.description}
                  image={banner.image}
                  label={banner.label}
                />
              </div>
            ))}
          </div>
        </section>

        <section className={"max-w-[1160px] mx-auto mt-24 px-4"}>
          <div className={"text-[#0D1D47] mb-8"}>
            <h2
              className={
                "tracking-[-2px] md:tracking-normal text-2xl md:text-3xl mb-10 font-bold text-center"
              }
            >
              戦略ラボとは？
            </h2>
            <div className="mb-10 w-full md:w-4/5 m-auto">
              {data && <Manga tactic={data} />}
            </div>
            <script async src="https://player.vimeo.com/api/player.js"></script>
            <h3
              className={
                "tracking-[1px] md:tracking-normal text-m md:text-l mb-3 px-2 leading-loose"
              }
            >
              中小企業の戦略ラボは経営の悩みを「学び」で解決できます。ついつい後回しにしがちな経営課題を「全員学習・社内実践・学び定着」で解決につなげることが可能です。
              <br />
              質問に答えていくだけで事業計画ができる「戦略見える化シート」や誰でも興味を持ってスキマ時間で学べるよう、わずか10分間で分かりやすく学べるオリジナル漫画のコンテンツを多数揃えています。毎日1つずつ、100以上の経営特化コンテンツがずっと無料で見れるなど、無料で多くのサービスを利用いただけます。
              <br />
              有料版では、コンテンツが毎日見放題になったり、学習のフィードバックや学びが継続する様々な機能が利用可能。さらに、プランによってはAIによる自動化も出来るようになるため、少数精鋭で自社の生産性を大きく高めることができます。まずは無料でお試しください。
            </h3>
          </div>
          <div className={"mb-10"}>
            <Link
              className={
                "text-white bg-[#E9530C] text-l md:text-xl flex items-center justify-center py-4 max-w-[500px] m-auto hover:opacity-60 duration-300 rounded font-bold"
              }
              href={"/about"}
            >
              ますは無料会員で試してみる
              <ArrowRightIcon className={"w-5 ml-4"} />
            </Link>
          </div>
        </section>

        <section className={"max-w-[1160px] mx-auto mt-24 mb-20 px-4"}>
          <div className={"mb-20"}>
            <div className={"mb-8"}>
              <div className={"text-[#0D1D47] mb-8"}>
                <h2
                  className={
                    "tracking-[-2px] md:tracking-normal text-2xl md:text-3xl mb-10 font-bold pt-10 text-center"
                  }
                >
                  戦略ラボを活用いただいているお客様のお声
                </h2>
              </div>
            </div>
            <div className={"md:grid grid-cols-2 gap-2 space-y-2 md:space-y-0"}>
              {voices.map((voice, index) => (
                <div className={"border p-5"} key={index}>
                  <div className="flex items-center mb-4">
                    <div className={"leading-[0] mr-2"}>
                      <Image
                        src={`/images/lp/${voice.image}`}
                        width={80}
                        height={80}
                        alt={""}
                        loading={"eager"}
                      />
                    </div>
                    <p className={""}>
                      {voice.companyName}
                      <br />
                      {voice.name}
                    </p>
                  </div>
                  <p className={"relative p-4 bg-gray-50 rounded text-sm"}>
                    <span
                      className={"absolute top-[-16px] left-4 text-gray-50"}
                    >
                      ▲
                    </span>
                    {voice.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className={"max-w-[1160px] mx-auto mt-24 px-4 mb-20"}>
          <div className={"text-[#0D1D47] mb-8"}>
            <h2
              className={
                "tracking-[-2px] md:tracking-normal text-2xl md:text-3xl mb-10 font-bold text-center pt-10"
              }
            >
              無料会員登録でここまで出来る！
            </h2>
            {free_contents.map((free_content, index) => (
              <div className={"border md:p-10 p-5 mb-5"} key={index}>
                <h3
                  className={
                    "tracking-[-2px] md:tracking-normal text-xl md:text-2xl mb-3 font-bold text-[#1D4ED8]"
                  }
                >
                  {free_content.headline}
                </h3>
                <p className={"mb-5"}>{free_content.subheading}</p>
                <div className={"md:p-10 p-5 md:flex md:items-center border"}>
                  <Image
                    src={`/images/index/${free_content.image}`}
                    width={415}
                    height={282}
                    alt={""}
                    className={"bg-white"}
                    loading={"eager"}
                  />
                  <div
                    className={"text-m md:text-xl p-5 md:p-10 text-[#0D1D47]"}
                  >
                    <p>{free_content.detail}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <p className={"text-center my-10"}>
            カスタムフォーム、タスク管理、いつでもチャットは1ユーザーのみ利用できます。機能の確認やトライアルでご活用ください。
          </p>
          <div className={"mb-10"}>
            <Link
              className={
                "text-white bg-[#E9530C] text-l md:text-xl flex items-center justify-center py-4 max-w-[500px] m-auto hover:opacity-60 duration-300 rounded font-bold"
              }
              href={"/about"}
            >
              無料会員で出来ることを
              <br className={"md:hidden"} />
              もっと詳しく見てみる
              <ArrowRightIcon className={"w-5 ml-4"} />
            </Link>
          </div>
        </section>

        <section className={"max-w-[1160px] mx-auto mt-24 px-4 mb-20"}>
          <div className={"text-[#0D1D47] mb-8"}>
            <h2
              className={
                "tracking-[-2px] md:tracking-normal text-2xl md:text-3xl mb-10 font-bold text-center pt-10"
              }
            >
              さらにプレミアムプランでは…
            </h2>
            {premium_contents.map((premium_content, index) => (
              <div className={"border md:p-10 p-5 mb-5"} key={index}>
                <h3
                  className={
                    "tracking-[-2px] md:tracking-normal text-xl md:text-2xl mb-3 font-bold text-[#1D4ED8]"
                  }
                >
                  {premium_content.headline}
                </h3>
                <p className={"mb-5"}>{premium_content.subheading}</p>
                <div className={"md:p-10 p-5 md:flex md:items-center border"}>
                  <Image
                    src={`/images/index/${premium_content.image}`}
                    width={415}
                    height={282}
                    alt={""}
                    className={"bg-white"}
                    loading={"eager"}
                  />
                  <div className={"text-m md:text-xl md:text-m p-5 md:p-10"}>
                    <p>{premium_content.detail}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className={"mb-10"}>
            <Link
              className={
                "text-white bg-[#E9530C] text-l md:text-xl flex items-center justify-center py-4 max-w-[500px] m-auto hover:opacity-60 duration-300 rounded font-bold"
              }
              href={"/premium"}
            >
              プレミアムプランで出来ることを
              <br className={"md:hidden"} />
              詳しく見てみる
              <ArrowRightIcon className={"w-5 ml-4"} />
            </Link>
          </div>
        </section>
        <ScrollToTop />
      </main>
    </>
  );
};

export async function getStaticProps() {
  const [news] = await Promise.all([
    microCMSClient.get({
      endpoint: "news",
      queries: {
        limit: 5,
      },
    }),
  ]);

  return {
    props: {
      news: news.contents,
    },
  };
}
export default TopPage;
