import React, { useState, useEffect } from "react";

export const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const ScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  if (!isVisible) return null;

  return (
    <div
      onClick={ScrollToTop}
      className="fixed bottom-5 right-5 cursor-pointer bg-blue-500 py-3 px-4 hover:bg-blue-700 text-white opacity-60 rounded-lg text-3xl duration-500 ease-in-out opacity-0"
    >
      ↑
    </div>
  );
};

export default ScrollToTop;
