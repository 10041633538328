import { client } from "@/api/client";
import { AxiosInstance } from "axios";
import { AuthHeaders } from "@/types/auth";
import { Tactic } from "@/types/tactic";
import { authHeaders } from "@/hoc/authHeaders";

export async function fetchTactics(
  url: string,
  headers: AuthHeaders
): Promise<Tactic[]> {
  const { data } = await client
    .get(url, {
      headers,
    })
    .catch((error) => {
      throw new Error(error.response.message);
    });
  return data;
}

export async function fetchTactic(url: string): Promise<Tactic> {
  const headers = await authHeaders();
  const { data } = await client
    .get(url, {
      headers,
    })
    .catch((error) => {
      throw new Error(error.response.message);
    });
  return data;
}

export interface TacticRepository {
  getTactic(id: string): Promise<Tactic>;
  listTactics(url: string): Promise<Tactic[]>;
}

class TacticRepositoryImpl implements TacticRepository {
  private readonly client: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.client = client;
  }

  async getTactic(id: string): Promise<Tactic> {
    const { data } = await this.client
      .get(`/api/v1/tactics/${id}`)
      .catch((error) => {
        throw new Error(error.response.message);
      });
    return data;
  }

  async listTactics(url: string): Promise<Tactic[]> {
    const { data } = await this.client.get(url);
    return data;
  }
}

export const newTacticRepository = (
  client: AxiosInstance
): TacticRepository => {
  return new TacticRepositoryImpl(client);
};
