import { default as NextHead } from "next/head";
import React from "react";

type Props = {
  title?: string | undefined;
  jsonLd?: string;
  image?: string;
  description?: string | undefined;
  canonicalPath?: string;
};

export const Head = ({
  title,
  jsonLd,
  description,
  image,
  canonicalPath,
}: Props) => {
  const OGP_URL = "https://app.s-d-lab.jp/ogp.jpg";
  const META_TITLE = title
    ? title + " | 中小企業の戦略ラボ"
    : "中小企業の戦略ラボ | 中小企業の戦略・学習・業務におけるPDCAを簡単に回せるオンラインプラットフォーム";
  const META_DESCRIPTION =
    "中小企業の戦略ラボは、中小企業の人材育成に特化したサービスです。オンラインでいつでも手軽に、漫画と専門家による面白くて役に立つコンテンツを学ぶことができます。新入社員から経営者まで、これ1つで実践的なビジネス知識を身につけることができ、社内に学習が定着していきます。";

  return (
    <NextHead>
      <link rel="icon" href="/favicon.ico" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="theme-color" content="#0D1D47" />
      <meta
        name="facebook-domain-verification"
        content="4sgtawngs5e5a3vipg8sjq3eiockm4"
      />
      <title>{META_TITLE}</title>
      <meta name="description" content={description || META_DESCRIPTION} />
      <meta
        property="og:description"
        content={description || META_DESCRIPTION}
      />
      <meta property="og:image" content={image || OGP_URL} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={META_TITLE} />
      <meta property="og:site_name" content={META_TITLE} />
      <meta
        property="og:description"
        content={description || META_DESCRIPTION}
      />
      <meta property="og:locale" content="ja_JP" />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:title" content={META_TITLE} />
      <meta
        property="twitter:description"
        content={description || META_DESCRIPTION}
      />
      <meta property="twitter:image" content={image || OGP_URL} />
      {jsonLd && (
        <script
          key="json-ld"
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: jsonLd }}
        />
      )}
      {canonicalPath && (
        <link rel="canonical" href={`https://app.s-d-lab.jp${canonicalPath}`} />
      )}
    </NextHead>
  );
};
